import { TrackData } from "./track";

export type DraggableStateFunction = React.Dispatch<
  React.SetStateAction<DraggableState>
>;

export enum ColumnTypes {
  SELECTED_TRACKS = "selectedTracks",
  RECOMENDED_TRACKS = "recomendedTracks",
}

export interface DraggableState {
  selectedTracks: TrackData[];
  recomendedTracks: TrackData[];
}

export interface Column {
  id: string;
  title?: string;
  trackIds: string[];
}

export const initialState: DraggableState = {
  selectedTracks: [],
  recomendedTracks: [],
};
