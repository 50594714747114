import { Box, Button, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FiRefreshCw,
  FiPlus,
  FiMinus,
  FiPlay,
  FiSearch,
  FiFilePlus,
  FiX,
  FiUserPlus,
  FiSquare,
} from "react-icons/fi";
import * as React from "react";

const useStyles: any = makeStyles({
  button: {
    color: "#8a8686",
    "&:hover": {
      color: "#363636",
    },
  },
});

// add a function import to the CustomIconButtonProps interface
interface CustomOvalButtonProps {
  text: string;
  style?: any;
  onClick?: any;
  size?: number;
  selected?: boolean;
  // add a generic function type to the CustomIconButtonProps interface
}

export function CustomOvalButton({
  text,
  style,
  onClick,
  size,
  selected,
}: CustomOvalButtonProps) {
  const theme = useTheme();
  const defaultStyle = {
    ...style,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderTopRightRadius: "15% 50%",
    borderBottomRightRadius: "15% 50%",
    borderTopLeftRadius: "15% 50%",
    borderBottomLeftRadius: "15% 50%",
  };
  const buttonStyle = selected
    ? {
        ...defaultStyle,
      }
    : { ...defaultStyle, backgroundColor: theme.palette.background.default };
  return (
    <Button style={{ ...buttonStyle }} variant="contained" onClick={onClick}>
      {text}
    </Button>
  );
}
