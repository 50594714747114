import { AudioState } from "../models/audioState";
import { ColumnTypes, DraggableState } from "../models/draggableState";
import { PlaylistSummary } from "../models/playlist";
import { TrackRecommendationData } from "../models/track";
import { UserState } from "../models/userState";
import { realRecommendedTracks } from "../test-data/recomendedTrack";
import { selectedTrackList } from "../test-data/selectedTracks";
import * as actionTypes from "./actions/actionTypes";

export type AppState = {
  draggableState: DraggableState;
  audioState: AudioState;
  userState: UserState;
  trackRecommendation: TrackRecommendationData;
  view: string;
  playlists: PlaylistSummary[];
  loadingUserInfo: boolean;
};

const initialState: AppState = {
  draggableState: {
    selectedTracks: [],
    recomendedTracks: [],
  },
  audioState: {
    audioObject: new Audio(),
    trackId: "",
    column: ColumnTypes.SELECTED_TRACKS,
    isPlaying: false,
    playIndex: 0,
  },
  userState: { email: "", uid: "" },
  trackRecommendation: {
    keyConfidence: 0,
    minKey: 1,
    maxKey: 12,
    minPop: 0,
    maxPop: 100,
    minBPM: 60,
    maxBPM: 160,
    useCustomKeyAndBPM: false,
    seedGenres: [],
  },
  view: "playlist",
  playlists: [],
  loadingUserInfo: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_DRAGGABLE_STATE:
      return {
        ...state,
        draggableState: action.payload,
      };
    case actionTypes.SET_AUDIO_STATE:
      return {
        ...state,
        audioState: action.payload,
      };
    case actionTypes.SET_USER_STATE:
      return {
        ...state,
        userState: action.payload,
      };
    case actionTypes.SET_TRACK_RECOMMENDATION:
      return {
        ...state,
        trackRecommendation: action.payload,
      };
    case actionTypes.SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    case actionTypes.SET_LOADING_USER_STATE:
      return {
        ...state,
        loadingUserInfo: action.payload,
      };
    case actionTypes.SET_PLAYLIST_SUMMARIES:
      return {
        ...state,
        playlists: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
