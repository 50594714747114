import * as React from "react";
import { CustomIconButton } from "../buttons/CustomIconButton";
import { updateRecommendations } from "../../utils/backendUtils";
import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import {
  clearSelectedTracks,
  setRecomendedTrackList,
} from "../../store/actions/actions";
import { CreatePlaylistDialog } from "../dialogs/CreatePlaylistDialog";

export default function ToolBar() {
  const dispatch: Dispatch<any> = useDispatch();
  const appState = useSelector((state: AppState) => state);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const userState = useSelector((state: AppState) => state.userState);
  const recomendedTracks = useSelector(
    (state: AppState) => state.draggableState.recomendedTracks
  );
  const buttonSize = 20;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderTopRightRadius: "30% 50%",
        borderBottomRightRadius: "30% 50%",
        borderTopLeftRadius: "30% 50%",
        borderBottomLeftRadius: "30% 50%",
        padding: 10,
      }}
    >
      {(userState.isSpotifyAuth || userState.isTidalAuth) && (
        <CreatePlaylistDialog buttonSize={buttonSize} />
      )}
      <div style={{ width: "10px" }} />
      {!isFetching ? (
        <CustomIconButton
          iconType="refresh"
          variant="contained"
          tip="Refresh recommended songs"
          size={buttonSize}
          onClick={async () => {
            setIsFetching(true);
            const trackList = await updateRecommendations(
              appState.draggableState.selectedTracks[
                appState.draggableState.selectedTracks.length - 1
              ],
              appState.trackRecommendation,
              appState.draggableState.selectedTracks
            );
            setIsFetching(false);
            if (!!trackList) dispatch(setRecomendedTrackList(trackList));
          }}
        />
      ) : (
        <CustomIconButton iconType="spinner" variant="contained" />
      )}
      <div style={{ width: "10px" }} />
      <CustomIconButton
        iconType="clear"
        variant="contained"
        tip="Clear all selected songs"
        size={buttonSize}
        onClick={() => {
          dispatch(clearSelectedTracks());
        }}
      />
      <div style={{ width: "10px" }} />
      <CustomIconButton
        iconType="video"
        variant="contained"
        tip="Tutorial Video"
        size={buttonSize}
        onClick={() => {
          window.open(
            "https://drive.google.com/file/d/1JqfjnPn7EOZkhZ8ooei5mQcmmxvYMmX7/view?usp=share_link",
            "_blank"
          );
        }}
      />
    </div> //https://drive.google.com/file/d/1JqfjnPn7EOZkhZ8ooei5mQcmmxvYMmX7/view?usp=share_link
  );
}
