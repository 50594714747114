import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { CustomIcon } from "../display/CustomIcon";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import { updateUserState, updateView } from "../../store/actions/actions";
import { additionalColorings } from "../..";
import { clearLocalEmailPassword } from "../../utils/localStorageUtils";

export default function UserMenu() {
  const dispatch: Dispatch<any> = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userState = useSelector((state: AppState) => state.userState);
  const displayName = !!userState.email
    ? userState.email.substring(0, userState.email.indexOf("@"))
    : "";
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          borderRadius: 5,
          padding: "5px 0px",
        }}
        sx={{
          "&:hover": {
            backgroundColor: additionalColorings.hoverOnDefaultBackground,
          },
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {/* <CustomIcon iconType="user" size={15} hasBackground={true} /> */}
          <Typography
            color={theme.palette.primary.contrastText}
            style={{ paddingLeft: 10, paddingRight: 5 }}
            variant="subtitle1"
          >
            {displayName}
          </Typography>
          <CustomIcon iconType="more" size={15} hasBackground={false} />
        </Box>
      </ButtonBase>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: "5px",
            backgroundColor: "black",
          },
        }}
      >
        <MenuItem
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
          sx={[
            {
              "&:hover": { backgroundColor: theme.palette.background.default },
            },
          ]}
          onClick={() => {
            dispatch(updateView("playlist"));
            handleClose();
          }}
        >
          <Typography style={{ color: "white" }}>Playlist Builder</Typography>
        </MenuItem>
        <MenuItem
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
          sx={[
            {
              "&:hover": { backgroundColor: theme.palette.background.default },
            },
          ]}
          onClick={() => {
            dispatch(updateView("settings"));
            handleClose();
          }}
        >
          <Typography style={{ color: "white" }}>Settings</Typography>
        </MenuItem>
        <MenuItem
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
          sx={[
            {
              "&:hover": { backgroundColor: theme.palette.background.default },
            },
          ]}
          onClick={() => {
            dispatch(updateUserState({}));
            clearLocalEmailPassword();
            handleClose();
          }}
        >
          <Typography style={{ color: "white" }}>Log out</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
