import { Box, Checkbox, Divider, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/reducer";
import { SmallScreenView } from "../views/SmallScreenView";
import { useState } from "react";
import { CustomOutlinedTextField } from "../components/inputs/CustomOutlinedTextField";
import { CustomButton } from "../components/buttons/CustomButton";
import { hashPassword } from "../utils/authUtils";
import { login, signUp } from "../utils/backendUtils";
import { updateUserState } from "../store/actions/actions";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import {
  doesEmailPass,
  doesPasswordPass,
  formControlForEmailAndPassword,
} from "../utils/formUtils";
import { getLocalEmailPassword } from "../utils/localStorageUtils";
import { TextButton } from "../components/buttons/TextButton";

function SignUp() {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [badEmail, setBadEmail] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const uid = useSelector((state: AppState) => state.userState.uid);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  React.useEffect(() => {
    const localStorage = getLocalEmailPassword();
    if (localStorage) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          height: "80vh",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            color={"white"}
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            turntbl
          </Typography>
          <Typography variant="h6" color={"white"} align="center">
            enhance your mixes and playlists
          </Typography>
          <Divider sx={{ bgcolor: "#181717", margin: "25px" }} light />
          <CustomOutlinedTextField
            label="email"
            onChange={setEmail}
            hideInputValue={false}
          />
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomOutlinedTextField
            label="password"
            onChange={setPassword}
            hideInputValue
          />
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomOutlinedTextField
            label="confirm password"
            onChange={setConfirmPassword}
            hideInputValue
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Checkbox
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              sx={{
                color: "white",
              }}
            />
            <Typography variant="subtitle1" color="white">
              remember me
            </Typography>
          </div>
          {badEmail && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                That email doesn't look right.
              </Typography>
            </div>
          )}
          {notFound && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                Username is already being used.
              </Typography>
            </div>
          )}
          {badPassword && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                Your password needs to be corrected.
              </Typography>
            </div>
          )}
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomButton
            style={{ width: "50%", padding: "10px 8px" }}
            selected
            text="Sign Up"
            onClick={async () => {
              if (
                !formControlForEmailAndPassword(
                  email,
                  password,
                  confirmPassword,
                  setBadEmail,
                  setBadPassword
                )
              ) {
                console.log("bad email or password");
                return;
              }
              const hashPass = hashPassword(password);
              const result = await signUp(email, hashPass, rememberMe);
              if (!result) {
                setNotFound(true);
                return;
              }
              setNotFound(false);
              dispatch(
                updateUserState({
                  email: email,
                  hashPass: hashPass,
                  isSpotifyAuth: false,
                  isTidalAuth: false,
                  isEmailVerified: false,
                })
              );
              navigate("/");
            }}
          />
          <TextButton
            text="Continue as guest"
            onClick={() => {
              dispatch(
                updateUserState({
                  isGuest: true,
                })
              );
            }}
            path="/"
          />
        </Box>
      </Box>
      <SmallScreenView />
    </Box>
  );
}

export default SignUp;
