import { Box, FormControl, Typography, useTheme, Divider } from "@mui/material";
import * as _ from "lodash";
import * as React from "react";
import { Dispatch } from "react";
import { MultipleSelect } from "../../components/inputs/MultipleSelect";
import { genres } from "../../defaults/genres";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import { CustomSwitch } from "../../components/inputs/CustomSwitch";
import { SliderInput } from "../../components/inputs/sliders/SliderInput";
import { InvertableSliderInput } from "../../components/inputs/sliders/InvertableSliderInput";

export function TrackInputView() {
  const trackRecommendationData = useSelector(
    (state: AppState) => state.trackRecommendation
  );
  const theme = useTheme();
  const userState = useSelector((state: AppState) => state.userState);
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <FormControl style={{ width: "900px" }}>
      <MultipleSelect
        label="Select Genre"
        options={genres}
        selected={
          trackRecommendationData.seedGenres
            ? trackRecommendationData.seedGenres
            : []
        }
      />
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />
      <CustomSwitch
        title={"Use Custom Key and BPM Range"}
        description={
          "Defaults to using one key and 5 BPM above and below most recent selected track"
        }
        checked={trackRecommendationData.useCustomKeyAndBPM}
      />
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />
      <InvertableSliderInput
        subtitle="Custom Key Range"
        description="Accepted key range for recommendations"
        minValue={trackRecommendationData.minKey}
        maxValue={trackRecommendationData.maxKey}
      />
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />
      <SliderInput
        subtitle="Custom BPM Range"
        description="Accepted BPM range for recommendations"
        keySuffix="bpm"
        minValue={trackRecommendationData.minBPM}
        maxValue={trackRecommendationData.maxBPM}
      />
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />
      <SliderInput
        subtitle="Custom Popularity Range"
        description="Accepted Popularity range for recommendations"
        keySuffix="pop"
        minValue={trackRecommendationData.minPop}
        maxValue={trackRecommendationData.maxPop}
        sliderMax={100}
        sliderMin={0}
      />
    </FormControl>
  );
}
