import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { StyledOutlinedTextField } from "./StyledOutlinedTextField";

// add interface that includes setPlaylistName, playlistName
interface CustomOutlinedTextFieldProps {
  hideInputValue: boolean;
  onChange: any;
  label: string;
}

export function CustomOutlinedTextField({
  hideInputValue,
  onChange,
  label,
}: CustomOutlinedTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const endAdornment = hideInputValue
    ? {
        endAdornment: (
          <IconButton
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            edge="end"
            style={{ color: "white" }}
          >
            {showPassword ? <FiEye /> : <FiEyeOff />}
          </IconButton>
        ),
      }
    : {};

  return (
    <StyledOutlinedTextField
          type={showPassword || !hideInputValue ? "text" : "password"}
          label={label}
          style={{ width: "100%", color: "white" }}
          onChange={(e) => onChange(e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={endAdornment}
          sx={{
            input: {
              paddingTop: "12px",
              paddingBottom: "12px",
            },
          }}
        />
  );
}
