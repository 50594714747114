import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { CustomIconButton } from "../components/buttons/CustomIconButton";
import { Link } from "react-router-dom";
import { TextButton } from "../components/buttons/TextButton";
import { HeaderTextButton } from "../components/buttons/HeaderTextButton";
import { TrackInputView } from "../views/settings-views/TrackInputView";
import { ProfileView } from "../views/settings-views/ProfileView";

function Settings() {
  const theme = useTheme();
  const [settingView, setSettingView] = React.useState("profile");

  return (
    <Box style={{ width: "100%", padding: "25px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          color={theme.palette.primary.contrastText}
          variant="h5"
          sx={{ fontWeight: "bold" }}
          style={{ paddingBottom: "25px" }}
        >
          Settings
        </Typography>

        <Link to="/">
          <CustomIconButton iconType="close" size={30} />
        </Link>
      </div>

      <Box
        style={{
          width: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "900px",
            paddingBottom: "30px",
          }}
        >
          <HeaderTextButton
            text="Profile"
            highlitghted={settingView === "profile"}
            onClick={() => setSettingView("profile")}
          />
          <HeaderTextButton
            text="Rec Input"
            highlitghted={settingView === "recommendations"}
            onClick={() => setSettingView("recommendations")}
          />
        </Box>
        {settingView === "profile" && <ProfileView />}
        {settingView === "recommendations" && <TrackInputView />}
      </Box>
    </Box>
  );
}

export default Settings;
