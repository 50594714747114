import { Box, Checkbox, Divider, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/reducer";
import { SmallScreenView } from "../views/SmallScreenView";
import { useState } from "react";
import { CustomOutlinedTextField } from "../components/inputs/CustomOutlinedTextField";
import { CustomButton } from "../components/buttons/CustomButton";
import { hashPassword } from "../utils/authUtils";
import { login } from "../utils/backendUtils";
import { updatePlaylistState, updateUserState } from "../store/actions/actions";
import { Dispatch } from "redux";
import { TextButton } from "../components/buttons/TextButton";
import { useNavigate } from "react-router-dom";
import { getLocalEmailPassword } from "../utils/localStorageUtils";

function Login() {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [notFound, setNotFound] = useState(false);

  React.useEffect(() => {
    const localStorage = getLocalEmailPassword();
    if (localStorage) {
      navigate("/");
      return;
    }
  }, []);
  return (
    <Box>
      <Box
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          height: "80vh",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            color={"white"}
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            turntbl
          </Typography>
          <Typography variant="h6" color={"white"} align="center">
            enhance your mixes and playlists
          </Typography>
          <Divider sx={{ bgcolor: "#181717", margin: "25px" }} light />
          <CustomOutlinedTextField
            label="email"
            onChange={setEmail}
            hideInputValue={false}
          />
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomOutlinedTextField
            label="password"
            onChange={setPassword}
            hideInputValue
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Checkbox
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              sx={{
                color: "white",
              }}
            />
            <Typography variant="subtitle1" color="white">
              remember me
            </Typography>
          </div>
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomButton
            style={{ width: "50%", padding: "10px 8px" }}
            selected
            text="Login"
            onClick={async () => {
              const hashPass = hashPassword(password);
              const result = await login(email, hashPass, rememberMe);
              if (!result) {
                setNotFound(true);
                return;
              }
              setNotFound(false);
              dispatch(
                updateUserState({
                  email,
                  hashPass,
                  isSpotifyAuth: result.isSpotifyAuth,
                  isTidalAuth: result.isTidalAuth,
                  isEmailVerified: result.isEmailVerified,
                })
              );
              dispatch(updatePlaylistState(result.playlistSummaries!));
              navigate("/");
            }}
          />
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "6px" }}
          >
            <Typography variant="subtitle2" color="white">
              {"Forgot your password, "}
            </Typography>
            <TextButton
              text="click here"
              onClick={() => {}}
              path="/forgot-password"
            />
          </div>
          <Divider sx={{ bgcolor: "#181717", margin: "20px" }} light />
          <Typography variant="subtitle1" color="white">
            Or
          </Typography>
          <Divider sx={{ bgcolor: "#181717", margin: "5px" }} light />
          <CustomButton
            path={"/sign-up"}
            style={{
              width: "50%",
              fontWeight: "bold",
              padding: "10px 8px",
              backgroundColor: "#181717",
              border: ".5px solid white",
            }}
            selected
            text="Sign Up"
          />
          <TextButton
            text="Continue as guest"
            onClick={() => {
              dispatch(
                updateUserState({
                  isGuest: true,
                })
              );
            }}
            path="/"
          />
        </div>
      </Box>
      <SmallScreenView />
    </Box>
  );
}

export default Login;
