import { Grid } from "@mui/material";
import { additionalColorings } from "../../..";
import { GridItem } from "../../display/GridItem";
import * as React from "react";

export function ImportPlaylistHeader() {
  return (
    <Grid
      container
      columnSpacing={1}
      style={{
        width: "100%",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid id="add-button" item xs={2} />
      <GridItem
        id={"Name"}
        text={"Name"}
        size={10}
        justifyContent={"flex-start"}
        itemStyle={{ color: additionalColorings.headerTextColor }}
      />
    </Grid>
  );
}
