import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomOvalButton } from "../components/buttons/CustomOvalButton";
import { CustomStandardTextField } from "../components/inputs/CustomStandardTextField";
import { sendForgotPasswordRequest } from "../utils/backendUtils";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [badEmail, setBadEmail] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box style={{ paddingTop: "50px" }}>
        <Typography variant="h4" color="white">
          Forgot password we got you
        </Typography>
        <CustomStandardTextField
          label="Email"
          onChange={setEmail}
          hideInputValue={false}
        />
        {badEmail && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography color={"white"}>
              That email doesn't look right.
            </Typography>
          </div>
        )}
        {displaySuccess && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography color={"white"}>
              We sent you an email with a link to reset your password.
            </Typography>
          </div>
        )}
        <CustomOvalButton
          style={{ marginTop: "10px" }}
          selected
          text="Submit"
          onClick={async () => {
            const regexp = new RegExp(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            const validEmail = regexp.test(email);
            if (!validEmail) {
              setBadEmail(true);
              return;
            }
            setBadEmail(false);
            setDisplaySuccess(true);
            sendForgotPasswordRequest(email);
          }}
        />
      </Box>
    </Box>
  );
}

export default ForgotPassword;
