import { Grid, List } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { TrackData } from "../../models/track";
import { Header } from "./TrackHeader";
import * as React from "react";
import { ColumnTypes } from "../../models/draggableState";
import { TrackItem } from "./TrackItem";

interface TrackDisplayHelperProps {
  id: string;
  selectedColumn?: boolean;
  trackDataList: TrackData[];
  displayIndex?: boolean;
}

function renderSelectedTrackData(trackData: TrackData[]) {
  return trackData.map((track, index) => {
    return (
      <Draggable
        draggableId={"selected".concat(track.id)}
        index={index}
        key={"selected".concat(track.id)}
      >
        {(provided) => (
          <TrackItem
            track={track}
            index={index}
            key={index}
            provided={provided}
            columnType={ColumnTypes.SELECTED_TRACKS}
          />
        )}
      </Draggable>
    );
  });
}

function renderRecommendedSongData(trackData: TrackData[]) {
  return trackData.map((track, index) => {
    return (
      <TrackItem
        track={track}
        index={index}
        key={track.id}
        columnType={ColumnTypes.RECOMENDED_TRACKS}
        provided={{}}
      />
    );
  });
}

export function TracksDisplayHelper({
  id,
  trackDataList,
}: TrackDisplayHelperProps) {
  return (
    <Grid item container direction="column">
      <Header />
      {id === "selected" ? (
        <Droppable droppableId={id} key={id}>
          {(provided) => (
            <List
              style={{
                overflow: "auto",
                width: "100%",
                display: "flex",
                marginBottom: "20px",
                flexDirection: "column",
                height: "73vh",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {renderSelectedTrackData(trackDataList)}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      ) : (
        <List
          style={{
            overflow: "auto",
            width: "100%",
            display: "flex",
            marginBottom: "20px",
            flexDirection: "column",
            height: "73vh",
          }}
        >
          {renderRecommendedSongData(trackDataList)}
        </List>
      )}
    </Grid>
  );
}
