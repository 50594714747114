import * as _ from "lodash";
import { TrackData } from "../models/track";

export function formatArtists(track: TrackData): string {
  const artists = _.reduce(
    track.artists,
    (result, artist) => {
      return result.concat(artist + ", ");
    },
    ""
  );
  return artists.slice(0, -2);
}

export function formatKey(track: TrackData): string {
  let mode = "a";
  if (track.mode === 1) {
    mode = "b";
  }
  return `${track.key}${mode}`;
}

export function formatDuration(track: TrackData, equalSpacing = false): string {
  let seconds = Math.round(track.duration % 60).toString();
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }
  return Math.floor(track.duration / 60)
    .toString()
    .concat(":")
    .concat(seconds);
}

export function formatBPM(track: TrackData): string {
  return Math.round(track.bpm).toString();
}
