import { Box } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/reducer";
import { AutoPlayBottomView } from "../views/AutoPlayBottomView";
import { SideBar } from "../views/SideBar";
import { SmallScreenView } from "../views/SmallScreenView";
import { Outlet, useNavigate } from "react-router-dom";
import { getLocalEmailPassword } from "../utils/localStorageUtils";
import { CentralSpinner } from "../components/display/CentralSpinner";

function Layout() {
  const navigate = useNavigate();
  const userState = useSelector((state: AppState) => state.userState);
  const loadingUserInfo = useSelector(
    (state: AppState) => state.loadingUserInfo
  );
  React.useEffect(() => {
    const localStorage = getLocalEmailPassword();
    if (!localStorage && !userState.email && !userState.isGuest) {
      navigate("/login");
      return;
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100vw",
            height: "100vh",
          }}
        >
          <SideBar isLoggedIn={!!userState.email} />
          {loadingUserInfo ? <CentralSpinner /> : <Outlet />}
        </div>
        <AutoPlayBottomView buttonSize={30} />
      </Box>
      <SmallScreenView />
    </Box>
  );
}

export default Layout;
