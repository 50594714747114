import { Box, Slider, TextField, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  updateTrackRecommendationMinMax,
} from "../../../store/actions/actions";
import { StyledOutlinedTextField } from "../StyledOutlinedTextField";
import { SubtitleAndDescriptionText } from "../../display/SubtitleAndDescriptionText";

// add interface that includes setPlaylistName, playlistName
interface InvertableSliderInputProps {
  subtitle: string;
  description: string;
  minValue?: number;
  maxValue?: number;
}

export function InvertableSliderInput({
  subtitle,
  description,
  minValue = 1,
  maxValue = 12,
}: InvertableSliderInputProps) {
  const theme = useTheme();
  const dispatch: Dispatch<any> = useDispatch();
  const [value, setValue] = React.useState<number[]>([minValue, maxValue]);
  const [isInverted, setIsInverted] = React.useState<boolean>(
    minValue > maxValue ? true : false
  );
  const [inverted, setInverted] = React.useState<"inverted" | "normal" | false>(
    isInverted ? "inverted" : "normal"
  );
  const handleChangeKey = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[0] === value[0] && newValue[1] === value[1]) {
      return;
    }
    if (newValue[1] === newValue[0] && !isInverted) {
      setInverted("inverted");
      setIsInverted(true);
    } else if (newValue[1] === newValue[0] && isInverted) {
      setInverted("normal");
      setIsInverted(false);
    }
    setValue(newValue as number[]);
    if (isInverted) {
      dispatch(
        updateTrackRecommendationMinMax("key", newValue[1], newValue[0])
      );
    } else {
      dispatch(
        updateTrackRecommendationMinMax("key", newValue[0], newValue[1])
      );
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SubtitleAndDescriptionText
        subtitle={subtitle}
        description={description}
      />

      <Slider
        size="small"
        min={1}
        max={12}
        style={{ width: "60%" }}
        value={value}
        track={inverted}
        onChange={handleChangeKey}
        valueLabelDisplay="auto"
        // when the track is inverted make the color blue
        sx={{
          "& .MuiSlider-track": {
            bgcolor:
              inverted === "inverted"
                ? "rgb(0,0,0, 0.5)"
                : theme.palette.primary.main,
          },
        }}
      />
    </Box>
  );
}
