export function formControlForEmailAndPassword(
  email: string,
  password: string,
  confirmPassword: string,
  setBadEmail: (value: React.SetStateAction<boolean>) => void,
  setBadPassword: (value: React.SetStateAction<boolean>) => void
): boolean {
  if (!doesEmailPass(email)) {
    setBadEmail(true);
    return false;
  }
  setBadEmail(false);
  if (!doesPasswordPass(password, confirmPassword)) {
    setBadPassword(true);
    return false;
  }
  return true;
}

export function doesPasswordPass(
  password: string,
  confirmPassword: string
): boolean {
  console.log(password, confirmPassword);
  if (password.length < 6 || password !== confirmPassword) {
    return false;
  }
  return true;
}

export function doesEmailPass(email: string): boolean {
  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regexp.test(email);
}
