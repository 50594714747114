import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import { login } from "../../utils/backendUtils";
import * as React from "react";
import { CustomOvalButton } from "../buttons/CustomOvalButton";
import { Dispatch } from "redux";
import { updateUserState } from "../../store/actions/actions";
import { hashPassword } from "../../utils/authUtils";
import { CustomButton } from "../buttons/CustomButton";

// add a function import to the CustomIconButtonProps interface
interface LoginSignUpDialog {
  type: string;
  // add a generic function type to the CustomIconButtonProps interface
}

export function LoginDialog() {
  const dispatch: Dispatch<any> = useDispatch();
  const uid = useSelector((state: AppState) => state.userState.uid);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [notFound, setNotFound] = useState(false);

  return (
    <div>
      <CustomButton
        style={{ marginRight: "10px", width: "100%", margin: "10px 0px" }}
        text="Login"
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            backgroundColor: "#181717",
          }}
        >
          <TextField
            id="standard-basic"
            variant="standard"
            label="Email"
            sx={{
              input: {
                color: "white",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "rgba(228, 219, 233, 0.25)",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  borderBottomColor: "rgba(228, 219, 233, 0.25)",
                  borderBottomWidth: "1px",
                },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            style={{ width: "100%", color: "white" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            label="Password"
            sx={{
              input: {
                color: "white",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "rgba(228, 219, 233, 0.25)",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  borderBottomColor: "rgba(228, 219, 233, 0.25)",
                  borderBottomWidth: "1px",
                },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            style={{ width: "100%", color: "white" }}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end"
                  style={{ color: "white" }}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </IconButton>
              ),
            }}
          />
          {notFound && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                The user and password does not exist.
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Checkbox
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              sx={{
                color: "white",
              }}
            />
            <Typography color={"white"}>Remember Me</Typography>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "#181717",
          }}
        >
          <CustomOvalButton
            selected
            text="Login"
            style={{ margin: "5px 10px" }}
            onClick={async () => {
              const hashPass = hashPassword(password);
              const result = await login(email, hashPass, rememberMe);
              if (!result) {
                setNotFound(true);
                return;
              }
              setNotFound(false);
              handleClose;
              dispatch(
                updateUserState({
                  email,
                  hashPass,
                  isSpotifyAuth: result.isSpotifyAuth,
                  isTidalAuth: result.isTidalAuth,
                  isEmailVerified: result.isEmailVerified,
                })
              );
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
