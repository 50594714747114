import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { TrackData } from "../../models/track";
import { ColumnTypes } from "../../models/draggableState";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedTrack,
  removeTrack,
  setAutoPlay,
  setNotPlaying,
} from "../../store/actions/actions";
import { AudioState } from "../../models/audioState";
import { AppState } from "../../store/reducer";
import * as React from "react";
import * as _ from "lodash";
import { colors } from "../../defaults/colors";
import {
  formatArtists,
  formatBPM,
  formatDuration,
  formatKey,
} from "../../utils/displayUtils";
import { CustomIconButton } from "../../components/buttons/CustomIconButton";
import { GridItem } from "../../components/display/GridItem";

interface TrackItemProps {
  track: TrackData;
  index: number;
  columnType: ColumnTypes;
  provided: any;
}

const useStyles: any = makeStyles({
  trackItem: {
    // assign background color with transpacent value
    backgroundColor: colors.backgroundBlack,
    "&:hover": {
      backgroundColor: colors.backgroundBlackHighlight,
    },
  },
});

function renderIndexColumn(
  isHovering: boolean,
  track: TrackData,
  audioState: AudioState,
  columnType: ColumnTypes,
  index: number,
  dispatch: Dispatch<any>
) {
  if (track.previewUrl === null) {
    return (
      <Grid
        item
        style={{
          maxWidth: "50px",
          minWidth: "50px",
        }}
      />
    );
  }
  if (
    audioState.column === columnType &&
    audioState.trackId === track.id &&
    audioState.isPlaying
  ) {
    return (
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "50px",
          minWidth: "50px",
        }}
      >
        <CustomIconButton
          iconType="pause"
          tip=""
          onClick={() => {
            dispatch(setNotPlaying());
          }}
        />
      </Grid>
    );
  } else if (isHovering) {
    return (
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "50px",
          minWidth: "50px",
        }}
      >
        <CustomIconButton
          iconType="play"
          tip=""
          onClick={() => {
            dispatch(setAutoPlay(track, columnType, index));
          }}
        />
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        style={{
          maxWidth: "50px",
          minWidth: "50px",
        }}
      ></Grid>
    );
  }
}

export function TrackItem({
  track,
  index,
  columnType,
  provided,
}: TrackItemProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const audioState = useSelector((state: AppState) => state.audioState);
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  if (_.keys(provided).length === 0) {
    provided["draggableProps"] = {};
    provided["dragHandleProps"] = {};
    provided["innerRef"] = null;
  }

  return (
    <Box
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      key={index}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={classes.trackItem}
    >
      <Grid
        container
        columnSpacing={1}
        style={{
          width: "100%",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "100vh",
        }}
      >
        {renderIndexColumn(
          isHovering,
          track,
          audioState,
          columnType,
          index,
          dispatch
        )}
        <GridItem
          id={"trackName"}
          text={track.name}
          size={6}
          justifyContent={"flex-start"}
        />
        <GridItem
          id="artistName"
          text={formatArtists(track)}
          size={3}
          justifyContent={"flex-start"}
        />
        <GridItem
          id="bpm-column"
          text={formatBPM(track)}
          size="auto"
          justifyContent={"flex-start"}
          itemStyle={{ maxWidth: "40px", minWidth: "40px" }}
        />
        <GridItem
          id="key-column"
          text={formatKey(track)}
          size="auto"
          justifyContent={"flex-start"}
          itemStyle={{ maxWidth: "40px", minWidth: "40px" }}
        />
        <GridItem
          id="time-column"
          text={formatDuration(track)}
          size="auto"
          justifyContent={"flex-start"}
          itemStyle={{ maxWidth: "50px", minWidth: "50px" }}
        />
        <Grid id="add-button" item xs={"auto"}>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center" }}
          >
            <CustomIconButton
              iconType={
                columnType === ColumnTypes.RECOMENDED_TRACKS ? "plus" : "minus"
              }
              tip=""
              onClick={() => {
                if (columnType === ColumnTypes.RECOMENDED_TRACKS) {
                  dispatch(addSelectedTrack(track));
                  dispatch(removeTrack(track, ColumnTypes.RECOMENDED_TRACKS));
                } else {
                  dispatch(removeTrack(track, ColumnTypes.SELECTED_TRACKS));
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
