import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

// add interface that includes setPlaylistName, playlistName
interface CustomStandardTextFieldProps {
  hideInputValue: boolean;
  onChange: any;
  label: string;
}

export function CustomStandardTextField({
  hideInputValue,
  onChange,
  label,
}: CustomStandardTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const endAdornment = hideInputValue
    ? {
        endAdornment: (
          <IconButton
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            edge="end"
            style={{ color: "white" }}
          >
            {showPassword ? <FiEye /> : <FiEyeOff />}
          </IconButton>
        ),
      }
    : {};

  return (
    <TextField
      type={showPassword || !hideInputValue ? "text" : "password"}
      id="standard-basic"
      variant="standard"
      label={label}
      sx={{
        input: {
          color: "white",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "rgba(228, 219, 233, 0.25)",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottomColor: "rgba(228, 219, 233, 0.25)",
          borderBottomWidth: "1px",
        },
      }}
      InputLabelProps={{
        style: { color: "white" },
      }}
      style={{ width: "100%", color: "white", paddingBottom: "2px" }}
      onChange={(e) => onChange(e.target.value)}
      InputProps={endAdornment}
    />
  );
}
