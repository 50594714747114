import { Box } from "@mui/material";
import * as React from "react";
import { SearchBar } from "../components/tool-bars/search-bar/SearchBar";

export function TopBar() {
  return (
    <Box
      id="top-bar"
      style={{
        width: "98%",
        zIndex: 5,
      }}
    >
      <SearchBar />
    </Box>
  );
}
