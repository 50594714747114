import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import { signUp } from "../../utils/backendUtils";
import * as React from "react";
import { CustomOvalButton } from "../buttons/CustomOvalButton";
import { Dispatch } from "redux";
import { updateUserState } from "../../store/actions/actions";
import { hashPassword } from "../../utils/authUtils";
import { CustomButton } from "../buttons/CustomButton";
import { CustomStandardTextField } from "../inputs/CustomStandardTextField";
import { TextButton } from "../buttons/TextButton";
import { formControlForEmailAndPassword } from "../../utils/formUtils";

// add a function import to the CustomIconButtonProps interface
interface SignUpDialogProps {
  landing?: boolean;
  closeLanding?: () => void;
  // add a generic function type to the CustomIconButtonProps interface
}

export function SignUpDialog({ landing, closeLanding }: SignUpDialogProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [badEmail, setBadEmail] = useState(false);
  const [mismatchedPassword, setMismatchedPassword] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const uid = useSelector((state: AppState) => state.userState.uid);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  return (
    <div>
      {landing ? (
        <TextButton
          path="/"
          text="sign up"
          onClick={() => {
            if (!!closeLanding) {
              closeLanding();
              handleOpen();
            }
          }}
        />
      ) : (
        <CustomButton
          style={{ marginRight: "10px", width: "100%", margin: "10px 0px" }}
          selected
          text="Sign Up"
          onClick={handleOpen}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            backgroundColor: "#181717",
          }}
        >
          <CustomStandardTextField
            label="Email"
            onChange={setEmail}
            hideInputValue={false}
          />
          <CustomStandardTextField
            label="Pasword"
            onChange={setPassword}
            hideInputValue
          />
          <CustomStandardTextField
            label="Confirm Pasword"
            onChange={setConfirmPassword}
            hideInputValue
          />
          {badEmail && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                That email doesn't look right.
              </Typography>
            </div>
          )}
          {notFound && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                Username is already being used.
              </Typography>
            </div>
          )}
          {badPassword && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                Your password has to be at least 6 characters long.
              </Typography>
            </div>
          )}
          {mismatchedPassword && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography color={"white"}>
                Those passwords didn't match.
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Checkbox
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              sx={{
                color: "white",
              }}
            />
            <Typography color={"white"}>Remember Me</Typography>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "#181717",
          }}
        >
          <CustomOvalButton
            selected
            text="Sign Up"
            onClick={async () => {
              if (
                !formControlForEmailAndPassword(
                  email,
                  password,
                  confirmPassword,
                  setBadEmail,
                  setBadPassword
                )
              ) {
                return;
              }
              const hashPass = hashPassword(password);
              const result = await signUp(email, hashPass, rememberMe);
              if (!result) {
                setNotFound(true);
                return;
              }
              setNotFound(false);
              dispatch(
                updateUserState({
                  email: email,
                  hashPass: hashPass,
                  isSpotifyAuth: false,
                  isTidalAuth: false,
                  isEmailVerified: false,
                })
              );
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
