import { ButtonBase, Typography } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

// add a function import to the CustomIconButtonProps interface
interface TextButtonProps {
  text: string;
  onClick?: any;
  path: string;
  // add a generic function type to the CustomIconButtonProps interface
}

export function TextButton({ onClick, text, path }: TextButtonProps) {
  return (
    <ButtonBase
      onClick={() => {
        onClick();
      }}
      component={RouterLink}
      to={path}
    >
      <Typography
        color="white"
        variant="subtitle2"
        style={{ marginLeft: "5px" }}
        sx={{ textDecoration: "underline" }}
      >
        {text}
      </Typography>
    </ButtonBase>
  );
}
