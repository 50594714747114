import { Grid } from "@mui/material";
import { GridItem } from "../../components/display/GridItem";
import * as React from "react";
import { additionalColorings } from "../..";

export function Header() {
  return (
    <Grid
      container
      columnSpacing={1}
      style={{
        width: "100%",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        id="add-button"
        item
        style={{
          maxWidth: "50px",
          minWidth: "50px",
        }}
      />
      <GridItem
        id={"Title"}
        text={"Title"}
        size={6}
        justifyContent={"flex-start"}
        itemStyle={{ color: additionalColorings.headerTextColor }}
      />
      <GridItem
        id="Artist"
        text={"Artist"}
        size={3}
        justifyContent={"flex-start"}
        itemStyle={{ color: additionalColorings.headerTextColor }}
      />
      <GridItem
        id="bpm"
        text={"BPM"}
        size="auto"
        justifyContent={"flex-start"}
        itemStyle={{
          maxWidth: "40px",
          minWidth: "40px",
          color: additionalColorings.headerTextColor,
        }}
      />
      <GridItem
        id="key-column"
        text={"Key"}
        size="auto"
        justifyContent={"flex-start"}
        itemStyle={{
          maxWidth: "40px",
          minWidth: "40px",
          color: additionalColorings.headerTextColor,
        }}
      />
      <GridItem
        id="time-column"
        text={"Time"}
        size="auto"
        justifyContent={"flex-start"}
        itemStyle={{
          maxWidth: "50px",
          minWidth: "50px",
          color: additionalColorings.headerTextColor,
        }}
      />
      <Grid id="add-button" item xs={"auto"}>
        <div style={{ width: "30px" }} />
        {/* <CustomIconButton iconType="plus" tip="" /> */}
      </Grid>
    </Grid>
  );
}
