import { Box, Button, ButtonBase, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { additionalColorings } from "../..";
import { CustomIcon } from "../display/CustomIcon";
import {
  Link as RouterLink,
} from "react-router-dom";

const useStyles: any = makeStyles({
  button: {
    color: "#8a8686",
    "&:hover": {
      color: "#363636",
    },
  },
});

// add a function import to the CustomIconButtonProps interface
interface SideBarButtonProps {
  text: string;
  onClick?: any;
  icon?: string;
  path: string
  // add a generic function type to the CustomIconButtonProps interface
}

export function SideBarButton({ text, onClick, icon, path }: SideBarButtonProps) {
  return (
    <ButtonBase
      component={RouterLink}
      to={path}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "5px 0px",
        borderRadius: 5,
        paddingLeft: "5px",
      }}
      sx={{
        "&:hover": {
          backgroundColor: additionalColorings.hoverOnDefaultBackground,
        },
      }}
      onClick={onClick}
    >
      <Typography variant="h6" style={{ color: "white" }}>
        {text}
      </Typography>
      {icon && (
        <CustomIcon
          iconType={icon}
          style={{ marginLeft: 10, paddingRight: 10 }}
          size={20}
        />
      )}
    </ButtonBase>
  );
}
