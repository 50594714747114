import { Box, Divider, Typography } from "@mui/material";
import * as React from "react";

export function SmallScreenView() {
  return (
    <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
      <div style={{ paddingTop: "100px" }} />
      <Typography
        variant="h4"
        color={"white"}
        align="center"
        sx={{ fontWeight: "bold" }}
      >
        turntbl
      </Typography>
      <Typography variant="h6" color={"white"} align="center">
        curate songs and enhance your mixes
      </Typography>
      <Divider sx={{ bgcolor: "#313030", margin: "15px" }} />
      <Typography variant="h6" color={"white"} align="center">
        We currently do not support smaller screens. Please use a larger device.
      </Typography>
    </Box>
  );
}
