export function setLocalEmailPassword(email: string, hashPass: string) {
  localStorage.setItem("email", email);
  localStorage.setItem("hashPass", hashPass);
}

export function clearLocalEmailPassword() {
  localStorage.removeItem("email");
  localStorage.removeItem("hashPass");
}

export function getLocalEmailPassword() {
  const email = localStorage.getItem("email");
  const hashPass = localStorage.getItem("hashPass");
  if (!email || !hashPass) {
    clearLocalEmailPassword(); // clear if one is missing
    return null;
  }
  return {
    email,
    hashPass,
  };
}
