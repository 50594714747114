import { Divider, List, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SideBarButton } from "../components/buttons/SideBarButton";
import { SideBarPlaylistButton } from "../components/buttons/SideBarPlaylistButton";
import { LoginDialog } from "../components/dialogs/LoginDialog";
import { PlaylistImportDialog } from "../components/dialogs/import-playlists/PlaylistImportDialog";
import { SignUpDialog } from "../components/dialogs/SignUpDialog";
import UserMenu from "../components/menus/UserMenu";
import { PlaylistSummary } from "../models/playlist";
import { UserState } from "../models/userState";
import { setSelectedTrackList } from "../store/actions/actions";
import { AppState } from "../store/reducer";
import { fetchUserPlaylist } from "../utils/backendUtils";
import { CentralSpinner } from "../components/display/CentralSpinner";

interface SideBarProps {
  isLoggedIn: boolean;
  // add a generic function type to the CustomIconButtonProps interface
}

function renderPlaylistButtons(
  playlistSummaries: PlaylistSummary[],
  userState: UserState,
  dispatch: Dispatch<any>
) {
  return playlistSummaries.map((playlist, index) => {
    return (
      <SideBarPlaylistButton
        key={index}
        text={playlist.name}
        icon={playlist.appName}
        onClick={async () => {
          const selectedSongs = await fetchUserPlaylist(
            playlist.id,
            playlist.appName,
            userState.email,
            userState.hashPass
          );
          if (!!selectedSongs) {
            dispatch(setSelectedTrackList(selectedSongs));
          }
        }}
      />
    );
  });
}

export function SideBar({ isLoggedIn }: SideBarProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const playlists = useSelector((state: AppState) => state.playlists);
  const userState = useSelector((state: AppState) => state.userState);
  const loadingUserInfo = useSelector(
    (state: AppState) => state.loadingUserInfo
  );
  return (
    <div
      id="top-bar"
      style={{
        alignItems: "center",
        minWidth: "300px",
        maxWidth: "300px",
        borderColor: "#black",
        borderStyle: "solid",
        borderWidth: "0px 6px 0px 0px",
        padding: "10px 14px",
      }}
    >
      {loadingUserInfo ? (
        <CentralSpinner />
      ) : isLoggedIn ? (
        <div>
          <UserMenu />
          <Divider sx={{ bgcolor: "#313030", margin: "10px 0px" }} light />

          <SideBarButton path="/" text="Builder" />
          <SideBarButton path="/settings" text="Settings" icon="setting" />
          <Divider sx={{ bgcolor: "#313030", margin: "10px 0px" }} light />
          <PlaylistImportDialog />
          <List>{renderPlaylistButtons(playlists, userState, dispatch)}</List>
        </div>
      ) : (
        <div>
          <LoginDialog />
          <SignUpDialog />
        </div>
      )}
    </div>
  );
}
