import {
  Box,
  FormControl,
  Typography,
  useTheme,
  Divider,
  Button,
} from "@mui/material";
import * as _ from "lodash";
import * as React from "react";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import { SubtitleAndDescriptionText } from "../../components/display/SubtitleAndDescriptionText";
import { FormAuthButton } from "../../components/inputs/FormAuthButton";
import {
  deleteAccount,
  resendVerificationEmail,
  spotifyAuth,
  tidalLogin,
} from "../../utils/backendUtils";
import { SubtitleDescriptionButton } from "../../components/buttons/SubtitleDescriptionButton";
import AreYouSureDialog from "../../components/dialogs/AreYouSureDialog";
import { useNavigate } from "react-router";

export function ProfileView() {
  const theme = useTheme();
  const userState = useSelector((state: AppState) => state.userState);
  const dispatch: Dispatch<any> = useDispatch();
  const navitate = useNavigate();

  return (
    <FormControl style={{ paddingTop: "20px", width: "900px" }}>
      <SubtitleAndDescriptionText
        subtitle="Email"
        description={userState.email!}
      />
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />

      {!userState.isEmailVerified ? (
        <SubtitleDescriptionButton
          subtitle="Veriy you email"
          description="Only email verified accounts can authorize other applciations. Please verify your email."
          childButton={
            <Button
              variant="contained"
              onClick={async () => {
                resendVerificationEmail(userState.email, userState.hashPass);
              }}
              style={{ width: "180px" }}
            >
              Verify Email
            </Button>
          }
        />
      ) : (
        <div>
          <SubtitleAndDescriptionText
            subtitle="Apps"
            description="Authorize the below apps with turntbl, import and create playlists with authorized apps."
          />
          <div style={{ display: "flex", paddingTop: "20px" }}>
            <FormAuthButton
              iconType="spotify"
              buttonSize={33}
              isAuthorized={userState.isSpotifyAuth}
              userState={userState}
              authFunction={spotifyAuth}
            />
            <div style={{ width: "25px" }} />
            <FormAuthButton
              buttonVariant="contained"
              buttonSize={20}
              iconType="tidal"
              isAuthorized={userState.isTidalAuth}
              userState={userState}
              authFunction={tidalLogin}
              buttonStyle={{ backgroundColor: "black" }}
              snackBar
            />
          </div>
        </div>
      )}
      <Divider sx={{ bgcolor: "#313030", margin: "20px" }} light />
      <SubtitleDescriptionButton
        subtitle="Delete Account"
        description="Not feeling it? Delete your account."
        childButton={
          <AreYouSureDialog
            openItem={<Button>Delete Account</Button>}
            title={"Delete your account?"}
            onYesClick={async () => {
              await deleteAccount(userState.email, userState.hashPass);
              // set userState to default
              dispatch({
                type: "SET_USER_STATE",
                payload: {},
              });
              navitate("/login");
            }}
          />
        }
      />
    </FormControl>
  );
}
