import { Box } from "@mui/material";
import * as React from "react";
import { Dispatch, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CustomOvalButton } from "../../components/buttons/CustomOvalButton";
import ToolBar from "../../components/tool-bars/ToolBar";
import { dragTrack } from "../../store/actions/actions";
import { AppState } from "../../store/reducer";
import { TopBar } from "../TopBar";
import { TracksDisplayHelper } from "./TracksDisplayHelper";

export function TrackDisplay() {
  const draggable = useSelector((state: AppState) => state.draggableState);
  const dispatch: Dispatch<any> = useDispatch();
  const [selectedView, setSelectedView] = useState("selected");
  const onDragEnd = (result: DropResult) => {
    dispatch(dragTrack(result));
  };

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "100px",
      }}
    >
      <TopBar />
      <Box
        style={{
          width: "98%",
          display: "flex",
          marginBottom: "20px",
        }}
      >
        <Box
          id="tabs"
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <CustomOvalButton
            selected={selectedView === "selected"}
            text="Selected"
            onClick={async () => {
              setSelectedView("selected");
            }}
          />
          <CustomOvalButton
            selected={selectedView === "recommended"}
            text="Recommended"
            onClick={async () => {
              setSelectedView("recommended");
            }}
          />
        </Box>
        <ToolBar />
      </Box>
      {selectedView === "selected" && (
        <DragDropContext onDragEnd={onDragEnd}>
          <TracksDisplayHelper
            key={"selected"}
            id={"selected"}
            selectedColumn={true}
            trackDataList={draggable.selectedTracks}
          />
        </DragDropContext>
      )}
      {selectedView === "recommended" && (
        <TracksDisplayHelper
          key={"recommended"}
          id={"recommended"}
          trackDataList={draggable.recomendedTracks}
        />
      )}
    </Box>
  );
}
