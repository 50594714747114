import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomOvalButton } from "../components/buttons/CustomOvalButton";
import { CustomStandardTextField } from "../components/inputs/CustomStandardTextField";
import { hashPassword } from "../utils/authUtils";
import { sendPasswordResetRequest } from "../utils/backendUtils";

function PasswordReset() {
  const { passwordLink } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [response, setResponse] = useState("");
  const [badPassword, setBadPassword] = useState(false);
  const [mismatchedPassword, setMismatchedPassword] = useState(false);
  React.useEffect(() => {
    console.log(passwordLink);
  }, [passwordLink, password]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box style={{ paddingTop: "50px" }}>
        <Typography variant="h4" color="white">
          Password Reset
        </Typography>
        <CustomStandardTextField
          label="Password"
          onChange={setPassword}
          hideInputValue
        />
        <CustomStandardTextField
          label="Confirm Password"
          onChange={setConfirmPassword}
          hideInputValue
        />
        <CustomOvalButton
          style={{ marginTop: "10px" }}
          selected
          text="Reset Password"
          onClick={async () => {
            if (!password || password.length < 6) {
              setBadPassword(true);
              return;
            }
            setBadPassword(false);
            if (password !== confirmPassword) {
              setMismatchedPassword(true);
              return;
            }
            if (!passwordLink) {
              return;
            }
            const hashPass = hashPassword(password);
            setResponse(await sendPasswordResetRequest(passwordLink, hashPass));
          }}
        />
        {response && <Typography color="white">{response}</Typography>}
      </Box>
    </Box>
  );
}

export default PasswordReset;
