import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  Divider,
  List,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/reducer";
import * as React from "react";
import { Dispatch } from "redux";
import { SideBarButton } from "../../buttons/SideBarButton";
import { CustomIconButton } from "../../buttons/CustomIconButton";
import { ImportPlaylistHeader } from "./ImportPlaylistHeader";
import { ImportPlaylistItem } from "./ImportPlaylistItem";
import { PlaylistSummary } from "../../../models/playlist";
import {
  getUserSpotifyPlaylists,
  getUserTidalPlaylists,
  putUserPlaylists,
} from "../../../utils/backendUtils";
import { updatePlaylistState } from "../../../store/actions/actions";
import { CustomButton } from "../../buttons/CustomButton";
import * as _ from "lodash";

function renderImportPlaylistItem(
  playlistData: PlaylistSummary[],
  selectedPlaylists: PlaylistSummary[],
  setSelectedPlaylists: React.Dispatch<React.SetStateAction<PlaylistSummary[]>>
) {
  return playlistData.map((playlist, index) => {
    return (
      <ImportPlaylistItem
        playlist={playlist}
        index={index}
        key={playlist.id}
        setSelected={setSelectedPlaylists}
        selectedPlaylists={selectedPlaylists}
      />
    );
  });
}

export function PlaylistImportDialog() {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = useState(false);
  const userState = useSelector((state: AppState) => state.userState);
  const userPlaylists = useSelector((state: AppState) => state.playlists);
  const [selectedApp, setSelectedApp] = useState<string | undefined>(undefined);
  const [playlists, setPlaylists] = useState<PlaylistSummary[]>([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState<PlaylistSummary[]>(
    []
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedPlaylists([]);
    setOpen(false);
  };

  return (
    <div>
      <SideBarButton
        path="/"
        text="Playlists"
        icon="more"
        onClick={handleOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#181717",
          }}
        >
          <Typography style={{ color: "white" }} sx={{ fontWeight: "bold" }}>
            Import Playlists
          </Typography>
          <CustomIconButton
            iconType="close"
            tip=""
            style={{ marginLeft: 10, paddingRight: 10 }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#181717" }}>
          <div>
            <Typography
              style={{ color: "white" }}
              variant="subtitle1"
              fontWeight={"bold"}
            >
              Select an app
            </Typography>
            <div style={{ padding: "16px 0px 0px 0px", display: "flex" }}>
              {userState.isSpotifyAuth && (
                <CustomIconButton
                  iconType="spotify"
                  size={43}
                  tip="Import playlist from Spotify"
                  onClick={async () => {
                    const userSpotifyPlaylists = await getUserSpotifyPlaylists(
                      userState.email,
                      userState.hashPass
                    );
                    if (!!userSpotifyPlaylists) {
                      // filter out all values that are already in the userState playlistSummary object
                      const filteredPlaylists = _.filter(
                        userSpotifyPlaylists,
                        (playlist) => {
                          return !_.some(userPlaylists, playlist);
                        }
                      );

                      setPlaylists(filteredPlaylists);
                      setSelectedApp("Spotify");
                    }
                  }}
                />
              )}
              <div style={{ width: "50px" }} />
              {userState.isTidalAuth && (
                <CustomIconButton
                  variant="contained"
                  iconType="tidal"
                  tip="Import playlist from Tidal"
                  size={30}
                  style={{ backgroundColor: "black" }}
                  onClick={async () => {
                    const userTidalPlaylists = await getUserTidalPlaylists(
                      userState.email,
                      userState.hashPass
                    );
                    if (!!userTidalPlaylists) {
                      const filteredPlaylists = _.filter(
                        userTidalPlaylists,
                        (playlist) => {
                          return !_.some(userPlaylists, playlist);
                        }
                      );
                      setPlaylists(filteredPlaylists);
                      setSelectedApp("Tidal");
                    }
                  }}
                />
              )}
            </div>
            <Divider sx={{ bgcolor: "#313030", margin: "15px" }} />
            {selectedApp && playlists.length !== 0 ? (
              <Box
                sx={{
                  minHeight: "50vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography
                    style={{ color: "white" }}
                    variant="subtitle1"
                    fontWeight={"bold"}
                    sx={{ paddingBottom: "5px" }}
                  >
                    {selectedApp} Playlists
                  </Typography>
                  <ImportPlaylistHeader />
                  <Box
                    sx={{
                      height: "30vh",
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      "&::-webkit-scrollbar": {
                        width: "0.4em",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "16px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                      },
                    }}
                  >
                    <List sx={{ padding: "0px" }}>
                      {renderImportPlaylistItem(
                        playlists,
                        selectedPlaylists,
                        setSelectedPlaylists
                      )}
                    </List>
                  </Box>
                </div>
                <div>
                  <Divider sx={{ bgcolor: "#313030", margin: "15px" }} />
                  <CustomButton
                    style={{ width: "20%" }}
                    selected
                    text={`(${selectedPlaylists.length}) Import`}
                    onClick={async () => {
                      dispatch(updatePlaylistState(selectedPlaylists));
                      putUserPlaylists(
                        selectedPlaylists,
                        userState.email,
                        userState.hashPass
                      );
                    }}
                  />
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  height: "50vh",
                }}
              >
                <Typography style={{ color: "white" }} variant="h5">
                  No app selected or no playlists found
                </Typography>
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
