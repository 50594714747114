import { Box, Slider, TextField, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { updateTrackRecommendationMinMax } from "../../../store/actions/actions";
import { StyledOutlinedTextField } from "../StyledOutlinedTextField";
import { SubtitleAndDescriptionText } from "../../display/SubtitleAndDescriptionText";

// add interface that includes setPlaylistName, playlistName
interface SliderInputProps {
  subtitle: string;
  description: string;
  keySuffix: string;
  minValue?: number;
  maxValue?: number;
  sliderMin?: number;
  sliderMax?: number;
}

export function SliderInput({
  subtitle,
  description,
  keySuffix,
  minValue = 120,
  maxValue = 130,
  sliderMin = 50,
  sliderMax = 160,
}: SliderInputProps) {
  const theme = useTheme();
  const dispatch: Dispatch<any> = useDispatch();
  const [value, setValue] = React.useState<number[]>([minValue, maxValue]);
  const minDistance = 10;
  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], sliderMax - minDistance);
        setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue([clamped - minDistance, clamped]);
      }
    } else {
      setValue(newValue as number[]);
    }
    dispatch(updateTrackRecommendationMinMax(keySuffix, value[0], value[1]));
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SubtitleAndDescriptionText
        subtitle={subtitle}
        description={description}
      />

      <Slider
        size="small"
        min={sliderMin}
        max={sliderMax}
        style={{ width: "60%" }}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
