import { Grid, Typography, useTheme } from "@mui/material";
import { TrackData } from "../../models/track";
import * as React from "react";

interface GridItemProps {
  size: number | "auto";
  text: string;
  id: string;
  alignItems?: string;
  justifyContent?: string;
  itemStyle?: any;
}

export function GridItem({
  size,
  text,
  id,
  itemStyle,
  alignItems = "center",
  justifyContent = "center",
}: GridItemProps) {
  const theme = useTheme();

  return (
    <Grid
      id={id}
      item
      lg={size}
      md={Number(size) ? Number(size) - 1 : "auto"}
      style={{
        display: "flex",
        alignItems,
        justifyContent,
      }}
    >
      <Typography
        noWrap={true}
        color={theme.palette.primary.contrastText}
        style={{ ...itemStyle }}
      >
        {text}
      </Typography>
    </Grid>
  );
}
