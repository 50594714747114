import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { additionalColorings } from "../..";
import { CustomIcon } from "../display/CustomIcon";

const useStyles: any = makeStyles({
  button: {
    color: "#8a8686",
    "&:hover": {
      color: "#363636",
    },
  },
});

// add a function import to the CustomIconButtonProps interface
interface SideBarButtonProps {
  text: string;
  onClick?: any;
  icon: string;
  // add a generic function type to the CustomIconButtonProps interface
}

export function SideBarPlaylistButton({
  text,
  onClick,
  icon,
}: SideBarButtonProps) {
  let size = 13;
  let style: any = { marginRight: "0px" };
  let hasBackground = false;
  if (icon === "tidal") {
    style = {
      ...style,
      marginRight: "6px",
      backgroundColor: "black",
    };
    size = 7;
    hasBackground = true;
  }
  return (
    <ButtonBase
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        padding: "5px 0px",
        borderRadius: 5,
        paddingLeft: "5px",
      }}
      sx={{
        "&:hover": {
          backgroundColor: additionalColorings.hoverOnDefaultBackground,
        },
      }}
      onClick={onClick}
    >
      <CustomIcon
        iconType={icon}
        hasBackground={hasBackground}
        size={size}
        style={style}
      />
      <Typography variant="subtitle1" noWrap style={{ color: "white" }}>
        {text}
      </Typography>
    </ButtonBase>
  );
}
