import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import reducer, { AppState } from "./store/reducer";
import { applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { Action, DispatchAction } from "./store/actions/actions";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";
import "@fontsource/barlow";

export const additionalColorings = {
  hoverOnDefaultBackground: "#333333",
  headerTextColor: "#C3C3C3",
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#646AFD",
    },
    secondary: {
      main: "#120053",
    },
    text: {
      primary: "rgba(4,4,4,0.87)",
    },
    background: {
      default: "#181717",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

const theme = createTheme(themeOptions);

const store: Store<AppState, Action<any>> & {
  dispatch: DispatchAction<any>;
} = createStore(reducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
