import { Box, InputBase, List, Paper, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { TrackData } from "../../../models/track";
import { searchTrack } from "../../../utils/backendUtils";
import { useEffect, useState } from "react";
import * as React from "react";
import { SearchItem } from "./SearchItem";
import { useDispatch } from "react-redux";
import { addSelectedTrack } from "../../../store/actions/actions";
import { Dispatch } from "redux";
import { CustomIcon } from "../../display/CustomIcon";

// create a function that will render searchItems
function renderSearchItems(
  tracks: TrackData[],
  addSongToPlaylistHelper: (track: TrackData) => void,
  updateRecommendationsHelper: (track: TrackData) => void
) {
  return tracks.map((track, index) => {
    return (
      <SearchItem
        key={track.id}
        track={track}
        index={index}
        addSongToPlaylistHelper={addSongToPlaylistHelper}
        updateRecommendationsHelper={updateRecommendationsHelper}
      />
    );
  });
}

export function SearchBar() {
  const dispatch: Dispatch<any> = useDispatch();
  const theme = useTheme();
  const [displayList, setDisplayList] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchTracks, setSearchTracks] = useState<TrackData[]>([]);
  const [trackSearch, setTrackSearch] = useState<string>("");
  const handleClick = () => {
    setDisplayList(true);
  };
  const handleIgnore = () => {
    setDisplayList(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (trackSearch === "") {
        setSearchTracks([]);
      } else {
        const searchedTracks = await searchTrack(trackSearch);
        setSearchTracks(searchedTracks);
        setIsSearching(false);
      }
    }, 1000);
    if (trackSearch === "") {
      setIsSearching(false);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [trackSearch]);

  const addSongToPlaylistHelper = async (track: TrackData) => {
    handleIgnore();
    dispatch(addSelectedTrack(track));
  };

  const updateRecommendationsHelper = async () => {
    handleIgnore();
  };

  const handleEnter = (e: React.KeyboardEvent) => {};

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        zIndex: 5,
      }}
    >
      <Paper
        component="form"
        style={{
          position: "relative",
          display: "flex",
          width: "60%",
          height: 40,
          // transform: "translateX(-50%)",
          borderTopRightRadius: "2% 30%",
          borderBottomRightRadius: "2% 30%",
          borderTopLeftRadius: "2% 30%",
          borderBottomLeftRadius: "2% 30%",
          zIndex: 5,
        }}
        onClick={handleClick}
        onSubmit={onSubmit}
      >
        {isSearching ? (
          <Box
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress
              size={20}
              style={{ marginLeft: 10, marginRight: 10 }}
            />
          </Box>
        ) : (
          <CustomIcon
            iconType="search"
            style={{ marginLeft: 10, paddingRight: 10 }}
          />
        )}
        <InputBase
          style={{ flex: 1, paddingTop: 0 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            setTrackSearch(e.target.value);
            setIsSearching(true);
          }}
          onKeyDown={handleEnter}
        />
      </Paper>
      {displayList && searchTracks.length !== 0 && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "fixed",
            justifyContent: "center",
            top: 60,
            left: "300px",
            marginLeft: "3%",
            width: "60%",
            backgroundColor: theme.palette.background.default,
            borderRadius: "10px",
            zIndex: 3,
          }}
        >
          <List style={{ width: "100%" }}>
            {renderSearchItems(
              searchTracks,
              addSongToPlaylistHelper,
              updateRecommendationsHelper
            )}
          </List>
        </Box>
      )}
      {displayList && (
        //postion a black box in the background
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: 2,
          }}
          onClick={handleIgnore}
        />
      )}
    </div>
  );
}
