import "./App.css";
import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoadingUserState,
  updatePlaylistState,
  updateUserState,
} from "./store/actions/actions";
import { login } from "./utils/backendUtils";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { AppState } from "./store/reducer";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Settings from "./pages/Settings";
import Layout from "./pages/Layout";
import MixBuilder from "./pages/MixBuilder";
import PasswordReset from "./pages/PasswordReset";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import {
  clearLocalEmailPassword,
  getLocalEmailPassword,
} from "./utils/localStorageUtils";

function App() {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    const localStorage = getLocalEmailPassword();
    if (!localStorage) {
      return;
    }
    const getUserInfo = async () => {
      try {
        console.log("getting user info");
        dispatch(updateLoadingUserState(true));
        const acountInfo = await login(
          localStorage.email,
          localStorage.hashPass,
          true
        );
        if (acountInfo) {
          dispatch(
            updateUserState({
              email: localStorage.email,
              hashPass: localStorage.hashPass,
              isSpotifyAuth: acountInfo.isSpotifyAuth,
              isTidalAuth: acountInfo.isTidalAuth,
              isEmailVerified: acountInfo.isEmailVerified,
            })
          );
          if (!!acountInfo.playlistSummaries) {
            dispatch(updatePlaylistState(acountInfo.playlistSummaries));
          }
        } else {
          clearLocalEmailPassword();
        }
        dispatch(updateLoadingUserState(false));
      } catch (err) {
        console.log(err);
        dispatch(updateLoadingUserState(false));
      }
    };
    getUserInfo();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MixBuilder />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route
          path="/password-reset/:passwordLink"
          element={<PasswordReset />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
