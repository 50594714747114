import {
  Dialog,
  DialogTitle,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AppState } from "../../store/reducer";
import {
  createSpotifyPlaylist,
  createTidalPlaylist,
  createTurntblPlaylist,
} from "../../utils/backendUtils";
import * as _ from "lodash";
import { CustomIconButton } from "../buttons/CustomIconButton";
import * as React from "react";
import { SubtitleAndDescriptionText } from "../display/SubtitleAndDescriptionText";
import { addPlaylist } from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";

// add a function import to the CustomIconButtonProps interface
interface CreatePlaylistDialogProps {
  buttonSize: number;
  // add a generic function type to the CustomIconButtonProps interface
}

export function CreatePlaylistDialog({
  buttonSize,
}: CreatePlaylistDialogProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = useState(false);
  const selectedTracks = useSelector(
    (state: AppState) => state.draggableState.selectedTracks
  );
  const userState = useSelector((state: AppState) => state.userState);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const closeSnackBar = () => setDisplaySnackBar(false);
  const [playlistName, setPlaylistName] = useState<string>("");
  const [displaySnackBar, setDisplaySnackBar] = useState<boolean>(false);

  return (
    <div>
      <CustomIconButton
        iconType="create-playlist"
        variant="contained"
        tip="Create your playlist"
        size={buttonSize}
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        aria-labelledby="customized-dialog-title"
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#181717",
          }}
        >
          <Typography
            style={{ width: "90%", color: "white" }}
            sx={{ fontWeight: "bold" }}
          >
            Create Playlist
          </Typography>
          <CustomIconButton
            iconType="close"
            tip=""
            style={{ marginLeft: 10, paddingRight: 10 }}
            onClick={handleClose}
          />
        </DialogTitle>
        <div style={{ backgroundColor: "#181717", padding: "16px 24px" }}>
          <Typography style={{ color: "white" }}>TITLE</Typography>
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{
              input: {
                color: "white",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "rgba(228, 219, 233, 0.25)",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  borderBottomColor: "rgba(228, 219, 233, 0.25)",
                  borderBottomWidth: "1px",
                },
            }}
            style={{ width: "100%", color: "white" }}
            onChange={(e) => setPlaylistName(e.target.value)}
          />
          <Divider sx={{ bgcolor: "#181717", margin: "15px" }} />
          <SubtitleAndDescriptionText
            subtitle="Details"
            style={{ width: "100%" }}
            description={`This playlist contains ${
              selectedTracks.length
            } songs with a total duration of ${_.reduce(
              selectedTracks,
              (result, track) => {
                return Math.floor(result + track.duration / 60);
              },
              0
            )} minutes.`}
          />
          <Divider sx={{ bgcolor: "#313030", margin: "15px" }} />
        </div>
        <div
          style={{
            backgroundColor: "#181717",
            padding: "0px 24px 16px 24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ width: "90%", color: "white" }}
            sx={{ fontWeight: "bold" }}
          >
            GENERATE
          </Typography>
          <div style={{ padding: "16px 0px", display: "flex" }}>
            <CustomIconButton
              iconType="turntbl"
              size={43}
              tip="Create playlist on turntbl"
              onClick={async () => {
                const uid = await createTurntblPlaylist(
                  playlistName,
                  selectedTracks,
                  userState.email,
                  userState.hashPass
                );
                if (uid) {
                  dispatch(
                    addPlaylist({
                      id: uid,
                      name: playlistName,
                      appName: "turntbl",
                      description: "",
                    })
                  );
                }
                handleClose();
                setDisplaySnackBar(true);
              }}
            />
            <div style={{ width: "30px" }} />
            {userState.isSpotifyAuth && (
              <CustomIconButton
                iconType="spotify"
                size={43}
                tip="Create playlist on Spotify"
                onClick={() => {
                  createSpotifyPlaylist(
                    playlistName,
                    selectedTracks,
                    userState.email,
                    userState.hashPass
                  );
                  handleClose();
                  setDisplaySnackBar(true);
                }}
              />
            )}
            <div style={{ width: "30px" }} />
            {userState.isTidalAuth && (
              <CustomIconButton
                variant="contained"
                iconType="tidal"
                tip="Create playlist on Tidal"
                size={30}
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  createTidalPlaylist(
                    playlistName,
                    selectedTracks,
                    userState.email,
                    userState.hashPass
                  );
                  handleClose();
                  setDisplaySnackBar(true);
                }}
              />
            )}
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={displaySnackBar}
        autoHideDuration={6000}
        onClose={closeSnackBar}
        message={`Creating playlist ${playlistName}`}
        style={{
          zIndex: 20,
        }}
      />
    </div>
  );
}
