import { Box, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";

// add interface that includes setPlaylistName, playlistName
interface FormInputProps {
  subtitle: string;
  description: string;
  style?: any;
}

export function SubtitleAndDescriptionText({
  subtitle,
  description,
  style,
}: FormInputProps) {
  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="subtitle2"
        color="white"
        fontWeight={"bold"}
      >
        {subtitle.toLocaleUpperCase()}
      </Typography>
      <Typography id="modal-modal-title" variant="subtitle2" color="#cfcfcf">
        {description}
      </Typography>
    </div>
  );
}
