import { Box, Checkbox, Grid } from "@mui/material";
import * as React from "react";
import { PlaylistSummary } from "../../../models/playlist";
import { GridItem } from "../../display/GridItem";

interface ImportPlaylistItemProps {
  index: number;
  playlist: PlaylistSummary;
  selectedPlaylists: PlaylistSummary[];
  setSelected: React.Dispatch<React.SetStateAction<PlaylistSummary[]>>;
}

export function ImportPlaylistItem({
  index,
  playlist,
  selectedPlaylists,
  setSelected,
}: ImportPlaylistItemProps) {
  return (
    <Box key={index} sx={{ maxHeight: "35px" }}>
      <Grid
        container
        columnSpacing={1}
        style={{
          width: "100%",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid id="add-button" item xs={2}>
          {" "}
          <Checkbox
            style={{
              color: "white",
            }}
            sx={{ color: "white", transform: "scale(.9)" }}
            onClick={() => {
              setSelected([...selectedPlaylists, { ...playlist }]);
            }}
          />
        </Grid>
        <GridItem
          id={"Title"}
          text={playlist.name}
          size={10}
          justifyContent={"flex-start"}
        />
      </Grid>
    </Box>
  );
}
