import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnTypes, DraggableState } from "../models/draggableState";
import { AppState } from "../store/reducer";
import {
  createSpotifyPlaylist,
  createTidalPlaylist,
} from "../utils/backendUtils";
import { CustomIconButton } from "../components/buttons/CustomIconButton";
import { FormInput } from "../components/inputs/FormInput";
import * as React from "react";
import {
  addSelectedTrack,
  removeTrack,
  setAutoPlay,
  setNotPlaying,
} from "../store/actions/actions";
import { Dispatch } from "redux";
import { TrackData } from "../models/track";
import {
  formatArtists,
  formatBPM,
  formatDuration,
  formatKey,
} from "../utils/displayUtils";

// add a function import to the CustomIconButtonProps interface
interface AutoPlayBottomViewProps {
  buttonSize: number;
  // add a generic function type to the CustomIconButtonProps interface
}

export function AutoPlayBottomView({ buttonSize }: AutoPlayBottomViewProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const audioState = useSelector((state: AppState) => state.audioState);
  const [open, setOpen] = useState(false);
  const tracks = useSelector((state: AppState) => state.draggableState);

  useEffect(() => {
    if (audioState.playNext) {
      let curPlayIndex = audioState.playIndex + 1;
      if (tracks[audioState.column].length <= curPlayIndex) {
        dispatch(setNotPlaying());
        return;
      }
      while (tracks[audioState.column][curPlayIndex].previewUrl === "") {
        curPlayIndex = curPlayIndex + 1;
        if (tracks[audioState.column].length <= curPlayIndex) {
          dispatch(setNotPlaying());
          return;
        }
      }
      dispatch(
        setAutoPlay(
          tracks[audioState.column][curPlayIndex],
          audioState.column,
          curPlayIndex
        )
      );
    }
  }, [audioState]);

  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "10px 14px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
        height: "7%",
      }}
    >
      {!!tracks[audioState.column][audioState.playIndex] && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            position: "fixed",
            width: "100%",
            zIndex: 10,
            height: "7%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "33%",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              style={{ color: "white", fontWeight: "bold", maxWidth: "100%" }}
            >
              {tracks[audioState.column][audioState.playIndex].name}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              style={{ color: "white", maxWidth: "80%" }}
            >
              {formatArtists(tracks[audioState.column][audioState.playIndex])}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "33%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "30%" }} />
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <CustomIconButton
                  size={30}
                  iconType="back"
                  tip=""
                  onClick={() => {
                    let curPlayIndex = audioState.playIndex - 1;
                    if (curPlayIndex < 0) {
                      dispatch(setNotPlaying());
                      return;
                    }
                    while (
                      !tracks[audioState.column][curPlayIndex].previewUrl
                    ) {
                      if (curPlayIndex < 0) {
                        dispatch(setNotPlaying());
                        return;
                      }
                      curPlayIndex = curPlayIndex - 1;
                    }
                    dispatch(
                      setAutoPlay(
                        tracks[audioState.column][curPlayIndex],
                        audioState.column,
                        curPlayIndex
                      )
                    );
                  }}
                />
                {audioState.isPlaying ? (
                  <CustomIconButton
                    size={30}
                    iconType="pause"
                    tip=""
                    onClick={() => {
                      dispatch(setNotPlaying());
                    }}
                  />
                ) : (
                  <CustomIconButton
                    size={30}
                    iconType="play"
                    tip=""
                    onClick={() => {
                      dispatch(
                        setAutoPlay(
                          tracks[audioState.column][audioState.playIndex],
                          audioState.column,
                          audioState.playIndex
                        )
                      );
                    }}
                  />
                )}
                <CustomIconButton
                  size={30}
                  iconType="forward"
                  tip=""
                  onClick={() => {
                    let curPlayIndex = audioState.playIndex + 1;
                    if (tracks[audioState.column].length <= curPlayIndex) {
                      dispatch(setNotPlaying());
                      return;
                    }
                    while (
                      !tracks[audioState.column][curPlayIndex].previewUrl
                    ) {
                      curPlayIndex = curPlayIndex + 1;
                      if (tracks[audioState.column].length <= curPlayIndex) {
                        dispatch(setNotPlaying());
                        return;
                      }
                    }
                    dispatch(
                      setAutoPlay(
                        tracks[audioState.column][curPlayIndex],
                        audioState.column,
                        curPlayIndex
                      )
                    );
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                {audioState.column === ColumnTypes.RECOMENDED_TRACKS && (
                  <CustomIconButton
                    tip="Add Song to Playlist"
                    size={30}
                    iconType="plus"
                    onClick={() => {
                      dispatch(
                        addSelectedTrack(
                          tracks[audioState.column][audioState.playIndex]
                        )
                      );
                      dispatch(
                        removeTrack(
                          tracks[audioState.column][audioState.playIndex],
                          audioState.column
                        )
                      );
                      let curPlayIndex = audioState.playIndex + 1;
                      if (tracks[audioState.column].length <= curPlayIndex) {
                        dispatch(setNotPlaying());
                        return;
                      }
                      while (
                        !tracks[audioState.column][curPlayIndex].previewUrl
                      ) {
                        if (tracks[audioState.column].length <= curPlayIndex) {
                          dispatch(setNotPlaying());
                          return;
                        }
                        curPlayIndex = curPlayIndex + 1;
                      }
                      dispatch(
                        setAutoPlay(
                          tracks[audioState.column][curPlayIndex],
                          audioState.column,
                          curPlayIndex
                        )
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "33%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "30%",
                minWidth: "150px",
                paddingRight: "40px",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "white", fontWeight: "bold" }}
              >
                {formatKey(tracks[audioState.column][audioState.playIndex])}
              </Typography>
              <Typography
                variant="h5"
                style={{ color: "white", fontWeight: "bold" }}
              >
                {formatBPM(tracks[audioState.column][audioState.playIndex])}
              </Typography>
              <Typography
                variant="h5"
                style={{ color: "white", fontWeight: "bold" }}
              >
                {formatDuration(
                  tracks[audioState.column][audioState.playIndex]
                )}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
