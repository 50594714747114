import { Box, Button, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles: any = makeStyles({
  button: {
    color: "#8a8686",
    "&:hover": {
      color: "#363636",
    },
  },
});

// add a function import to the CustomIconButtonProps interface
interface CustomButtonProps {
  text: string;
  style?: any;
  onClick?: any;
  size?: number;
  selected?: boolean;
  path?: string;
  // add a generic function type to the CustomIconButtonProps interface
}

export function CustomButton({
  text,
  style,
  onClick,
  size,
  selected,
  path,
}: CustomButtonProps) {
  const theme = useTheme();
  const defaultStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "6px 8px",
    ...style,
  };
  const buttonStyle = selected
    ? {
        ...defaultStyle,
      }
    : { ...defaultStyle, backgroundColor: theme.palette.background.default };
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {path ? (
        <Button
          component={RouterLink}
          to={path}
          style={{ ...buttonStyle }}
          variant="contained"
          onClick={onClick}
        >
          {text}
        </Button>
      ) : (
        <Button
          style={{ ...buttonStyle }}
          variant="contained"
          onClick={onClick}
        >
          {text}
        </Button>
      )}
    </Box>
  );
}
