import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomIconButton } from "../../buttons/CustomIconButton";
import { TrackData } from "../../../models/track";
import { GridItem } from "../../display/GridItem";
import _ from "lodash";
import * as React from "react";
import {
  formatArtists,
  formatBPM,
  formatDuration,
  formatKey,
} from "../../../utils/displayUtils";
import { additionalColorings } from "../../..";

export interface SearchItemProps {
  track: TrackData;
  index: number;
  addSongToPlaylistHelper: (track: TrackData) => void;
  updateRecommendationsHelper: (track: TrackData) => void;
}

export function SearchItem({
  track,
  index,
  addSongToPlaylistHelper,
}: SearchItemProps) {
  return (
    <ListItemButton
      key={track.id}
      onClick={() => {
        addSongToPlaylistHelper(track);
      }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "55px",
      }}
      sx={[
        {
          "&:hover": {
            backgroundColor: additionalColorings.hoverOnDefaultBackground,
          },
        },
      ]}
    >
      <ListItemText
        primary={
          <Typography
            variant="body1"
            style={{ color: "#FFFFFF", fontWeight: "bold" }}
          >
            {track.name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" style={{ color: "#FFFFFF" }}>
            {formatArtists(track)}
          </Typography>
        }
      />

      <Grid container spacing={1} style={{ maxWidth: "20%" }}>
        <GridItem
          id={"key"}
          text={formatKey(track)}
          size={4}
          justifyContent={"flex-start"}
          itemStyle={{ fontWeight: "bold" }}
        />
        <GridItem
          id={"tempo"}
          text={formatBPM(track)}
          size={4}
          justifyContent={"flex-start"}
          itemStyle={{ fontWeight: "bold" }}
        />
        <GridItem
          id={"duration"}
          text={formatDuration(track)}
          size={4}
          justifyContent={"flex-start"}
          itemStyle={{ fontWeight: "bold" }}
        />
      </Grid>
    </ListItemButton>
  );
}
